import { verticalSlugMap } from './vertical';
import { encodeToJson } from '../components/FastChannelSelector/encodeToJson';

export const AD_LEVELS = {
  SHOW_ALL_ADS: 0,
  SHOW_ONLY_PREROLL: 1,
  SHOW_ONLY_MIDROLL: 2,
  SHOW_NO_ADS: 3,
  DEFAULT: 2,
};

const encodedString = encodeToJson();

// Create a variable with the url for each of the local streams. Add them to an object.
export const BAY_AREA_STREAM = encodedString ? `https://nbcbayarea.com/portableplayer/?CID=1:4:3519840&videoID=&origin=nbcbayarea.com&fullWidth=y&autoplay=true&xumoOverride=${encodedString}` : 'https://nbcbayarea.com/portableplayer/?CID=1:4:3519840&videoID=&origin=nbcbayarea.com&fullWidth=y&autoplay=true';
export const BOSTON_STREAM = encodedString ? `https://nbcboston.com/portableplayer/?CID=1:5:3349031&videoID=&origin=nbcboston.com&fullWidth=y&autoplay=true&xumoOverride=${encodedString}` : 'https://nbcboston.com/portableplayer/?CID=1:5:3349031&videoID=&origin=nbcboston.com&fullWidth=y&autoplay=true';
export const CHICAGO_STREAM = encodedString ? `https://nbcchicago.com/portableplayer/?CID=1:6:3010684&videoID=214364229946&origin=nbcchicago.com&fullWidth=y&autoplay=true&xumoOverride=${encodedString}` : 'https://nbcchicago.com/portableplayer/?CID=1:6:3010684&videoID=214364229946&origin=nbcchicago.com&fullWidth=y&autoplay=true';
export const DFW_STREAM = encodedString ? `https://nbcdfw.com/portableplayer/?CID=1:8:3523824&videoID=&origin=nbcdfw.com&fullWidth=y&autoplay=true&xumoOverride=${encodedString}` : 'https://nbcdfw.com/portableplayer/?CID=1:8:3523824&videoID=&origin=nbcdfw.com&fullWidth=y&autoplay=true';
export const HARTFORD_STREAM = encodedString ? `https://nbcconnecticut.com/portableplayer/?CID=1:7:3274515&videoID=&origin=nbcconnecticut.com&fullWidth=y&autoplay=true&xumoOverride=${encodedString}` : 'https://nbcconnecticut.com/portableplayer/?CID=1:7:3274515&videoID=&origin=nbcconnecticut.com&fullWidth=y&autoplay=true';
export const LA_STREAM = encodedString ? `https://nbclosangeles.com/portableplayer/?CID=1:9:3396742&videoID=&origin=nbclosangeles.com&fullWidth=y&autoplay=true&xumoOverride=${encodedString}` : 'https://nbclosangeles.com/portableplayer/?CID=1:9:3396742&videoID=&origin=nbclosangeles.com&fullWidth=y&autoplay=true';
export const MIAMI_STREAM = encodedString ? `https://nbcmiami.com/portableplayer/?CID=1:10:3294236&videoID=&origin=nbcmiami.com&fullWidth=y&autoplay=true&xumoOverride=${encodedString}` : 'https://nbcmiami.com/portableplayer/?CID=1:10:3294236&videoID=&origin=nbcmiami.com&fullWidth=y&autoplay=true';
export const NYC_STREAM = encodedString ? `https://www.nbcnewyork.com/portableplayer/?CID=1:2:5351877&videoID=&origin=nbcnewyork.com&fullWidth=y&autoplay=true&xumoOverride=${encodedString}` : 'https://www.nbcnewyork.com/portableplayer/?CID=1:2:5351877&videoID=&origin=nbcnewyork.com&fullWidth=y&autoplay=true';
export const PHILLY_STREAM = encodedString ? `https://nbcphiladelphia.com/portableplayer/?CID=1:12:3841075&videoID=&origin=nbcphiladelphia.com&fullWidth=y&autoplay=true&xumoOverride=${encodedString}` : 'https://nbcphiladelphia.com/portableplayer/?CID=1:12:3841075&videoID=&origin=nbcphiladelphia.com&fullWidth=y&autoplay=true';
export const SD_STREAM = encodedString ? `https://nbcsandiego.com/portableplayer/?CID=1:13:3497501&videoID=&origin=nbcsandiego.com&fullWidth=y&autoplay=true&xumoOverride=${encodedString}` : 'https://nbcsandiego.com/portableplayer/?CID=1:13:3497501&videoID=&origin=nbcsandiego.com&fullWidth=y&autoplay=true';
export const DC_STREAM = encodedString ? `https://nbcwashington.com/portableplayer/?CID=1:14:3600727&videoID=&origin=nbcwashington.com&fullWidth=y&autoplay=true&xumoOverride=${encodedString}` : 'https://nbcwashington.com/portableplayer/?CID=1:14:3600727&videoID=&origin=nbcwashington.com&fullWidth=y&autoplay=true';
export const TELEMUNDO_NORESTE_STREAM = encodedString ? `https://telemundo47.com/portableplayer/?CID=1:25:2469836&videoID=&origin=telemundo47.com&fullWidth=y&autoplay=true&xumoOverride=${encodedString}` : 'https://telemundo47.com/portableplayer/?CID=1:25:2469836&videoID=&origin=telemundo47.com&fullWidth=y&autoplay=true';
export const TELEMUNDO_FLORIDA_STREAM = encodedString ? `https://telemundo51.com/portableplayer/?CID=1:24:2525424&videoID=&origin=telemundo51.com&fullWidth=y&autoplay=true&xumoOverride=${encodedString}` : 'https://telemundo51.com/portableplayer/?CID=1:24:2525424&videoID=&origin=telemundo51.com&fullWidth=y&autoplay=true';
export const TELEMUNDO_CALIFORNIA_STREAM = encodedString ? `https://telemundo52.com/portableplayer/?CID=1:21:2641429&videoID=&origin=telemundo52.com&fullWidth=y&autoplay=true&xumoOverride=${encodedString}` : 'https://telemundo52.com/portableplayer/?CID=1:21:2641429&videoID=&origin=telemundo52.com&fullWidth=y&autoplay=true';
export const TELEMUNDO_TEXAS_STREAM = encodedString ? `https://telemundodallas.com/portableplayer/?CID=1:17:2422881&videoID=&origin=telemundodallas.com&fullWidth=y&autoplay=true&xumoOverride=${encodedString}` : 'https://telemundodallas.com/portableplayer/?CID=1:17:2422881&videoID=&origin=telemundodallas.com&fullWidth=y&autoplay=true';

/**
 * @typedef {'MSNBC'|'MSNBC_TVE'|'NEWS_NOW'|'NOTICIAS_AHORA'|'TODAY_ALL_DAY'} STREAM_KEY
 */

/**
 * @readonly
 * @enum {STREAM_KEY}
 */
export const STREAM_KEYS = {
  DATELINE: 'DATELINE',
  MSNBC: 'MSNBC',
  MSNBC_TVE: 'MSNBC_TVE',
  NEWS_NOW: 'NEWS_NOW',
  NOTICIAS_AHORA: 'NOTICIAS_AHORA',
  TELEMUNDO_AL_DIA: 'TELEMUNDO_AL_DIA',
  SKY_NEWS: 'SKY_NEWS',
  TODAY_ALL_DAY: 'TODAY_ALL_DAY',
  SPORTS: 'NBC_SPORTS',
  BAY_AREA: BAY_AREA_STREAM,
  BOSTON: BOSTON_STREAM,
  CHICAGO: CHICAGO_STREAM,
  DFW: DFW_STREAM,
  HARTFORD: HARTFORD_STREAM,
  LA: LA_STREAM,
  MIAMI: MIAMI_STREAM,
  NYC: NYC_STREAM,
  PHILLY: PHILLY_STREAM,
  SD: SD_STREAM,
  DC: DC_STREAM,
  TELEMUNDO_NORESTE: TELEMUNDO_NORESTE_STREAM,
  TELEMUNDO_FLORIDA: TELEMUNDO_FLORIDA_STREAM,
  TELEMUNDO_CALIFORNIA: TELEMUNDO_CALIFORNIA_STREAM,
  TELEMUNDO_TEXAS: TELEMUNDO_TEXAS_STREAM,
};

export const NOTICIAS_AHORA_PID = '2000501';
export const TELEMUNDO_AL_DIA_PID = '2000502';

export const NEWS_NOW_PID = '2007524';

export const MSNBC_TVE_PID = '2007680';
export const MSNBC_TVE_PID_TEST = '2000762';

export const TODAY_ALL_DAY_PID = '2004490';

export const SPORTS_PID = '2008635';

export const LIVE_VIDEO_PID_MAP = {
  [STREAM_KEYS.NEWS_NOW]: NEWS_NOW_PID,
  [NEWS_NOW_PID]: STREAM_KEYS.NEWS_NOW,
  [STREAM_KEYS.MSNBC]: MSNBC_TVE_PID,
  [STREAM_KEYS.MSNBC_TVE]: MSNBC_TVE_PID,
  [MSNBC_TVE_PID]: STREAM_KEYS.MSNBC_TVE,
  [STREAM_KEYS.TODAY_ALL_DAY]: TODAY_ALL_DAY_PID,
  [TODAY_ALL_DAY_PID]: STREAM_KEYS.TODAY_ALL_DAY,
  [STREAM_KEYS.TELEMUNDO_AL_DIA]: TELEMUNDO_AL_DIA_PID,
  [TELEMUNDO_AL_DIA_PID]: STREAM_KEYS.TELEMUNDO_AL_DIA,
  [STREAM_KEYS.NOTICIAS_AHORA]: NOTICIAS_AHORA_PID,
  [NOTICIAS_AHORA_PID]: STREAM_KEYS.NOTICIAS_AHORA,
  [STREAM_KEYS.SPORTS]: SPORTS_PID,
  [SPORTS_PID]: STREAM_KEYS.SPORTS,
};

export const LIVE_VIDEO_STREAM_SHORT_TITLE = {
  [STREAM_KEYS.NEWS_NOW]: 'NBC News NOW',
  [NEWS_NOW_PID]: 'NBC News NOW',
  [STREAM_KEYS.TODAY_ALL_DAY]: 'Today All Day',
  [TODAY_ALL_DAY_PID]: 'Today All Day',
  [STREAM_KEYS.MSNBC_TVE]: 'MSNBC',
  [MSNBC_TVE_PID]: 'MSNBC',
  [STREAM_KEYS.NOTICIAS_AHORA]: 'Noticias Telemundo Ahora',
  [NOTICIAS_AHORA_PID]: 'Noticias Telemundo Ahora',
  [STREAM_KEYS.TELEMUNDO_AL_DIA]: 'Telemundo Al Día',
  [TELEMUNDO_AL_DIA_PID]: 'Telemundo Al Día',
  [STREAM_KEYS.SPORTS]: 'NBC Sports',
  [SPORTS_PID]: 'NBC Sports',
};

export const STREAM_TO_BRAND_MAP = {
  [STREAM_KEYS.NEWS_NOW]: verticalSlugMap.news,
  [STREAM_KEYS.MSNBC_TVE]: verticalSlugMap.msnbc,
  [STREAM_KEYS.TODAY_ALL_DAY]: verticalSlugMap.today,
  [STREAM_KEYS.NOTICIAS_AHORA]: verticalSlugMap.noticias,
  [STREAM_KEYS.TELEMUNDO_AL_DIA]: verticalSlugMap.telemundo,
};

export const NEWS_NOW_DEFAULT_SLATE = 'https://media-cldnry.s-nbcnews.com/image/upload/q_auto:eco/f_auto/newsgroup-logos/nbcnewsnow/linear-video-slate/default-1920x1080.png';
export const MSNBC_DEFAULT_SLATE = 'https://media-cldnry.s-nbcnews.com/image/upload/q_auto:eco/f_auto/newsgroup-logos/msnbc/linear-video-slate/default-1920x1080.png';
export const TODAY_ALL_DAY_DEFAULT_SLATE = 'https://media-cldnry.s-nbcnews.com/image/upload/q_auto:eco/f_auto/newscms/2023_16/3603395/today_all_day.jpg';
export const NOTICIAS_AHORA_DEFAULT_SLATE = 'https://media-cldnry.s-nbcnews.com/image/upload/rockcms/2024-04/NTASLATE-b832f3.png';
export const TELEMUNDO_AL_DIA_DEFAULT_SLATE = 'https://media-cldnry.s-nbcnews.com/image/upload/rockcms/2025-01/Slate-Al-Dia-3ba1b3.jpg';
const ELECTION_SLATE = 'https://media-cldnry.s-nbcnews.com/image/upload/q_auto:eco/f_auto/newscms/2024_39/3663668/el24_harris_trump_face_off_no_logo.png';
export const DATELINE_DEFAULT_SLATE = 'https://media-cldnry.s-nbcnews.com/image/upload/v1732225710/assets/Dateline247_KeyArt_1920x1080.jpg';
export const SKY_NEWS_DEFAULT_SLATE = 'https://media-cldnry.s-nbcnews.com/image/upload/v1732296003/assets/skynews_live_stream_1920x1080.jpg';
export const BREAKING_SLATE = 'https://media-cldnry.s-nbcnews.com/image/upload/rockcms/2021-07/nebula-cms-fallback-logos-breaking-4ad6c4.png';
export const SPORTS_SLATE = 'https://media-cldnry.s-nbcnews.com/image/upload/rockcms/2025-04/sportsnowslate1920x1080-65dc1b.jpg';

export const STREAM_TO_SLATE_MAP = {
  [STREAM_KEYS.NEWS_NOW]: NEWS_NOW_DEFAULT_SLATE,
  [STREAM_KEYS.MSNBC_TVE]: MSNBC_DEFAULT_SLATE,
  [STREAM_KEYS.TODAY_ALL_DAY]: TODAY_ALL_DAY_DEFAULT_SLATE,
  [STREAM_KEYS.NOTICIAS_AHORA]: NOTICIAS_AHORA_DEFAULT_SLATE,
  [STREAM_KEYS.TELEMUNDO_AL_DIA]: TELEMUNDO_AL_DIA_DEFAULT_SLATE,
  [STREAM_KEYS.DATELINE]: DATELINE_DEFAULT_SLATE,
  [STREAM_KEYS.SKY_NEWS]: SKY_NEWS_DEFAULT_SLATE,
  [STREAM_KEYS.SPORTS]: SPORTS_SLATE,
  election: ELECTION_SLATE,
};

const NOTICIAS_AHORA_JSON_LD_METADATA = {
  front: 'noticias-telemundo-ahora',
  title: 'Noticias Telemundo AHORA: Noticias en español 24/7',
  description: 'Noticias Telemundo AHORA es el canal 24 horas de noticias de Telemundo.'
    + 'Todas las noticias de última hora en español de EE.UU., México, Latinoamérica y el mundo online.',
  thumbnailUrl: 'https://media-cldnry.s-nbcnews.com/image/upload/rockcms/2024-04/NTASLATE-b832f3.png',
  uploadDate: '2024-03-25',
  author: 'Noticias Telemundo',
  startDate: '2024-03-25T10:39:48+00:00',
  pid: parseInt(NOTICIAS_AHORA_PID, 10),
};

const TELEMUNDO_AL_DIA_JSON_LD_METADATA = {
  front: 'telemundo-al-dia',
  title: 'Telemundo Al Día',
  description: 'La fuente de información más confiable de la comunidad hispana en EE.UU. '
    + 'que ofrece las noticias más importantes y lo último del entretenimiento y el deporte.',
  thumbnailUrl: 'https://media-cldnry.s-nbcnews.com/image/upload/rockcms/2025-01/Slate-Al-Dia-3ba1b3.jpg',
  uploadDate: '2025-01-17',
  author: 'Telemundo',
  startDate: '2025-01-10T10:39:48+00:00',
  pid: parseInt(TELEMUNDO_AL_DIA_PID, 10),
};

const NEWS_NOW_JSON_LD_METADATA = {
  front: 'now',
  title: 'Watch NBC News NOW: Live TV | NBC News',
  description: 'NBC News NOW is an online streaming network from NBC News where users can find '
    + 'the latest stories and breaking news on world news and US news.',
  thumbnailUrl: 'https://media-cldnry.s-nbcnews.com/image/upload/newsgroup-logos/nbcnewsnow/external-video-slate/default-1920x1080.jpg',
  uploadDate: '2020-01-30',
  author: 'NBC News',
  startDate: '2020-01-30T10:39:48+00:00',
  pid: parseInt(NEWS_NOW_PID, 10),
};

const TODAY_ALL_DAY_JSON_LD_METADATA = {
  front: 'allday',
  title: 'TODAY All Day: Stream TODAY News and Show Segments | TODAY',
  description: 'Watch TODAY Show favorites, celebrity interviews, show exclusives, food, '
    + 'recipes, lifestyle tips and more on TODAY All Day, a streaming network from TODAY.',
  thumbnailUrl: 'https://sprtsecureassets.akamaized.net/operations/slates/tad_huge_logo.jpg',
  uploadDate: '2018-01-21',
  author: 'TODAY',
  startDate: '2018-01-21T10:39:48+00:00',
  pid: parseInt(TODAY_ALL_DAY_PID, 10),
};

const MSNBC_TVE_JSON_LD_METADATA = {
  front: 'msnbc',
  title: 'Stream MSNBC: New episodes and breaking news coverage',
  description: 'Stream MSNBC shows live and stay up-to-date on breaking news. Catch the latest episodes of The Rachel Maddow Show, Morning Joe, The ReidOut and more.',
  thumbnailUrl: 'https://media-cldnry.s-nbcnews.com/image/upload/newscms/2023_16/3603394/msnbc_0.jpg',
  uploadDate: '2018-01-21',
  author: 'MSNBC',
  startDate: '2018-01-21T10:39:48+00:00',
  pid: parseInt(MSNBC_TVE_PID, 10),
};

export const LIVE_VIDEO_STREAM_JSON_LD_METADATA = {
  [STREAM_KEYS.NEWS_NOW]: NEWS_NOW_JSON_LD_METADATA,
  [NEWS_NOW_JSON_LD_METADATA.front]: NEWS_NOW_JSON_LD_METADATA,
  [STREAM_KEYS.TODAY_ALL_DAY]: TODAY_ALL_DAY_JSON_LD_METADATA,
  [TODAY_ALL_DAY_JSON_LD_METADATA.front]: TODAY_ALL_DAY_JSON_LD_METADATA,
  [STREAM_KEYS.MSNBC_TVE]: MSNBC_TVE_JSON_LD_METADATA,
  [STREAM_KEYS.MSNBC]: MSNBC_TVE_JSON_LD_METADATA,
  [STREAM_KEYS.NOTICIAS_AHORA]: NOTICIAS_AHORA_JSON_LD_METADATA,
  [NOTICIAS_AHORA_JSON_LD_METADATA.front]: NOTICIAS_AHORA_JSON_LD_METADATA,
  [STREAM_KEYS.TELEMUNDO_AL_DIA]: TELEMUNDO_AL_DIA_JSON_LD_METADATA,
  [TELEMUNDO_AL_DIA_JSON_LD_METADATA.front]: TELEMUNDO_AL_DIA_JSON_LD_METADATA,
};

/**
 * Given a corresponding key (e.g. 'NEWS_NOW'), get the default PID for that stream.
 * @param  {STREAM_KEYS} key Key of the stream
 * @returns {number|null}     PID
 */
export function getPIDforPlayerKey(key) {
  if (LIVE_VIDEO_PID_MAP[key]) {
    return parseInt(LIVE_VIDEO_PID_MAP[key], 10);
  }
  return null;
}

/**
 * Get the key (e.g. 'NEWS_NOW') given a PID
 * @param  {string}      pid The Playmaker ID of the video stream
 * @returns {STREAM_KEYS|null}     Returns a key representing what the content of the
 * stream is
 */
export function getPlayerKeyForPID(pid) {
  if (LIVE_VIDEO_PID_MAP[pid]) {
    return LIVE_VIDEO_PID_MAP[pid];
  }
  return null;
}

/**
 *
 * @param {string} key
 */
export const getDefaultMetadata = (key) => {
  const shared = {
    playmakerArt: null,
    playmakerAutoplayMuted: true,
    playmakerPlayBehavior: 'clickToPlay',
    playmakerDescription: null,
    playmakerRenderMobileCover: true,
  };

  switch (key) {
    case MSNBC_TVE_PID:
    case 'msnbc':
      return {
        ...shared,
        playmakerId: MSNBC_TVE_PID,
        // Override in curation or use schedule data
        playmakerTitle: false,
        // Do not display on cover
        playmakerRenderMobileCover: false,
        streamId: STREAM_KEYS.MSNBC_TVE,
      };

    case MSNBC_TVE_PID_TEST:
      return {
        ...shared,
        playmakerId: MSNBC_TVE_PID_TEST,
        // Override in curation or use schedule data
        playmakerTitle: false,
        // Do not display on cover
        playmakerRenderMobileCover: false,
        streamId: STREAM_KEYS.MSNBC_TVE,
      };

    case TODAY_ALL_DAY_PID:
    case 'today':
      return {
        ...shared,
        playmakerId: TODAY_ALL_DAY_PID,
        playmakerTitle: 'Today All Day',
        playmakerDescription: 'Watch TODAY All Day. A streaming channel from TODAY.',
        streamId: STREAM_KEYS.TODAY_ALL_DAY,
      };

    case NEWS_NOW_PID:
    case 'news':
      return {
        ...shared,
        playmakerId: NEWS_NOW_PID,
        // eslint-disable-next-line max-len
        playmakerTitle: 'NBC News NOW',
        // eslint-disable-next-line max-len
        playmakerDescription: 'NBC News NOW is a streaming network from NBC News. NOW features deep reporting, thoughtful storytelling, and live, breaking news from the global resources of NBC News.',
        streamId: STREAM_KEYS.NEWS_NOW,
      };

    case NOTICIAS_AHORA_PID:
    case 'noticias':
      return {
        ...shared,
        playmakerId: NOTICIAS_AHORA_PID,
        // eslint-disable-next-line max-len
        playmakerTitle: 'Noticias Telemundo AHORA',
        // eslint-disable-next-line max-len
        playmakerDescription: 'Noticias Telemundo AHORA es el canal 24 horas de noticias de Telemundo. Todas las noticias de última hora en español de EE.UU., México, Latinoamérica y el mundo.',
        streamId: STREAM_KEYS.NOTICIAS_AHORA,
      };

    case TELEMUNDO_AL_DIA_PID:
    case 'telemundo':
      return {
        ...shared,
        playmakerId: TELEMUNDO_AL_DIA_PID,
        // eslint-disable-next-line max-len
        playmakerTitle: 'Telemundo Al Día',
        // eslint-disable-next-line max-len
        playmakerDescription: 'La fuente de información más confiable de la comunidad hispana en EE.UU. que ofrece las noticias más importantes y lo último del entretenimiento y el deporte.',
        streamId: STREAM_KEYS.TELEMUNDO_AL_DIA,
      };

    case SPORTS_PID:
    case 'sports':
      return {
        ...shared,
        playmakerId: SPORTS_PID,
        // Override in curation or use schedule data
        playmakerTitle: 'NBC Sports',
        // Do not display on cover
        playmakerRenderMobileCover: false,
        streamId: STREAM_KEYS.SPORTS,
      };

    default:
      return shared;
  }
};

/**
 *
 * @param {boolean} defaultValue
 * @param {string} override
 */
const mergeOverride = (defaultValue, override) => (
  defaultValue === 'other'
    ? override
    : defaultValue
);

/**
 * Merge live stream configuration from curation with defaults
 * @param {object} metadata
 * @param {string} vertical
 * @param {string} defaultPlaymakerId
 */
export function getPlaymakerMetadata(metadata, vertical = 'nbcnews', defaultPlaymakerId) {
  const {
    playmakerId: metaPlaymakerId,
    playmakerIdOverride,
    playmakerToggle,
    ...remainingMetadata
  } = metadata;

  // Playmaker is turned OFF
  if (playmakerToggle !== true) {
    return { playmakerToggle };
  }
  // There are two inputs for Playmaker ID:
  //  - a select and a textbox for injecting new pids
  const playmakerId = mergeOverride(metaPlaymakerId, playmakerIdOverride);

  // Curator doesn't send default ids back, so first use the playmakerID
  // then fall back to the default in the package def file
  // then fall back to default configuration for vertical:
  const metaDefaults = getDefaultMetadata(playmakerId || defaultPlaymakerId || vertical);

  // Merge defaults with configurations to get overrides
  const {
    playmakerArt: metaPlaymakerArt,
    playmakerArtOverride,
    ...mergedMetadata
  } = {
    playmakerId,
    ...metaDefaults,
    ...remainingMetadata,
  };

  // There are two inputs for Playmaker Art
  //  - a select and a textbox for injecting new guids)
  let playmakerArt = metaPlaymakerArt;
  if (metaPlaymakerArt === 'other') {
    playmakerArt = playmakerArtOverride;
  } else if (metaPlaymakerArt === 'news-now-breaking-slate') {
    playmakerArt = BREAKING_SLATE;
  }

  let { playmakerSlot } = mergedMetadata;
  if (typeof playmakerSlot !== 'undefined') {
    playmakerSlot = parseInt(playmakerSlot, 10);
  }

  return {
    ...mergedMetadata,
    playmakerArt,
    playmakerSlot,
    playmakerToggle,
  };
}

/**
 *
 * @param {object} metadata
 */
export function isAutoplay(metadata) {
  return getPlaymakerMetadata(metadata).playmakerPlayBehavior === 'autoplay';
}
